"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.severitiesOrder = void 0;
exports.severitiesOrder = [
    "formal verification",
    "gas saving",
    "base-fee",
    "lead-auditor",
    "minor",
    "low",
    "medium",
    "high",
    "critical",
];
