"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HATTokenLockFactory_abi = void 0;
exports.HATTokenLockFactory_abi = [
    {
        inputs: [
            { internalType: "address", name: "_masterCopy", type: "address" },
            { internalType: "address", name: "_governance", type: "address" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    { inputs: [], name: "MasterCopyCannotBeZero", type: "error" },
    {
        anonymous: false,
        inputs: [{ indexed: true, internalType: "address", name: "masterCopy", type: "address" }],
        name: "MasterCopyUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
            { indexed: true, internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "contractAddress", type: "address" },
            { indexed: true, internalType: "bytes32", name: "initHash", type: "bytes32" },
            { indexed: true, internalType: "address", name: "beneficiary", type: "address" },
            { indexed: false, internalType: "address", name: "token", type: "address" },
            { indexed: false, internalType: "uint256", name: "managedAmount", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "startTime", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "endTime", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "periods", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "releaseStartTime", type: "uint256" },
            { indexed: false, internalType: "uint256", name: "vestingCliffTime", type: "uint256" },
            { indexed: false, internalType: "bool", name: "revocable", type: "bool" },
            { indexed: false, internalType: "bool", name: "canDelegate", type: "bool" },
        ],
        name: "TokenLockCreated",
        type: "event",
    },
    {
        inputs: [
            { internalType: "address", name: "_token", type: "address" },
            { internalType: "address", name: "_owner", type: "address" },
            { internalType: "address", name: "_beneficiary", type: "address" },
            { internalType: "uint256", name: "_managedAmount", type: "uint256" },
            { internalType: "uint256", name: "_startTime", type: "uint256" },
            { internalType: "uint256", name: "_endTime", type: "uint256" },
            { internalType: "uint256", name: "_periods", type: "uint256" },
            { internalType: "uint256", name: "_releaseStartTime", type: "uint256" },
            { internalType: "uint256", name: "_vestingCliffTime", type: "uint256" },
            { internalType: "bool", name: "_revocable", type: "bool" },
            { internalType: "bool", name: "_canDelegate", type: "bool" },
        ],
        name: "createTokenLock",
        outputs: [{ internalType: "address", name: "contractAddress", type: "address" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "masterCopy",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "nonce",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [{ internalType: "address", name: "_masterCopy", type: "address" }],
        name: "setMasterCopy",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
