import styled, { css } from "styled-components";
import { getSpacing } from "styles";

export const StyledVulnerabilitySeverityForm = styled.div<{ expanded: boolean; showNftInfo: boolean }>(
  ({ expanded, showNftInfo }) => css`
    .header {
      width: 100%;
      background: var(--background-3);
      display: flex;
      align-items: center;
      color: var(--white);
      font-size: var(--small);
      text-transform: uppercase;
      font-weight: 700;
      padding: ${getSpacing(2)};
      border-bottom: 1px solid var(--grey-600);
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: var(--secondary);
      }

      span {
        margin-left: ${getSpacing(1)};
      }
    }

    .severity {
      width: 100%;
      background: var(--background-3);
      margin-bottom: ${getSpacing(2)};
      padding: ${getSpacing(2)};
      padding-top: ${getSpacing(3)};
      overflow: hidden;
      height: auto;

      ${!expanded &&
      css`
        height: 0;
        padding: 0;
      `}

      height: ${expanded ? "auto" : "0"};

      .content {
        .subcontent {
          display: flex;
          flex-direction: column;

          .w-40 {
            width: 40%;
          }

          .numbers-container {
            margin-bottom: ${getSpacing(2)};
            display: flex;
            gap: ${getSpacing(3)};

            .percentage-container,
            .pointings-container {
              width: 100%;
              margin-bottom: ${getSpacing(1)};

              .calculations {
                margin: ${getSpacing(1)} 0;
                padding: 0 ${getSpacing(2)};
                color: var(--white);
                font-size: var(--xxsmall);

                span {
                  color: var(--secondary-light);
                  font-weight: 700;
                }
              }
            }
          }
        }

        hr {
          border: 1px solid var(--grey-700);
          margin: ${getSpacing(3)} 0;
        }

        .description {
          color: var(--white);
          font-size: var(--medium);
          font-weight: 700;
        }

        .icons-content {
          display: flex;
          justify-content: center;
          gap: ${getSpacing(3)};
        }

        .nft-section {
          .nft-info {
            height: ${showNftInfo ? "auto" : "0"};
            overflow: hidden;

            .half {
              width: 50%;
            }

            .inputs {
              display: flex;
              gap: ${getSpacing(3)};
            }
          }
        }

        .encryption-toggle {
          margin-bottom: ${getSpacing(3)};

          display: flex;
          align-items: center;

          span {
            padding-bottom: ${getSpacing(1)};
            font-weight: 700;
          }

          > div {
            margin-bottom: 0;
          }
        }
      }
    }

    .pointings-container {
      display: flex;
      gap: ${getSpacing(3)};

      & > div:first-child {
        width: 45%;
      }

      & > div:not(:first-child) {
        width: 27%;
      }
    }
  `
);
