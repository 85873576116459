"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HATAirdropFactory_abi = void 0;
exports.HATAirdropFactory_abi = [
    {
        inputs: [{ internalType: "contract IHATToken", name: "_HAT", type: "address" }],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    { inputs: [], name: "ContractIsNotHATAirdrop", type: "error" },
    { inputs: [], name: "HATAirdropInitializationFailed", type: "error" },
    { inputs: [], name: "RedeemDataArraysLengthMismatch", type: "error" },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "_hatAirdrop", type: "address" },
            { indexed: false, internalType: "bytes", name: "_initData", type: "bytes" },
            { indexed: false, internalType: "contract IERC20", name: "_token", type: "address" },
            { indexed: false, internalType: "uint256", name: "_totalAmount", type: "uint256" },
        ],
        name: "HATAirdropCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
            { indexed: true, internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "_owner", type: "address" },
            { indexed: false, internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "TokensWithdrawn",
        type: "event",
    },
    {
        inputs: [],
        name: "HAT",
        outputs: [{ internalType: "contract IHATToken", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_implementation", type: "address" },
            { internalType: "bytes", name: "_initData", type: "bytes" },
            { internalType: "contract IERC20", name: "_token", type: "address" },
            { internalType: "uint256", name: "_totalAmount", type: "uint256" },
        ],
        name: "createHATAirdrop",
        outputs: [{ internalType: "address", name: "result", type: "address" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "isAirdrop",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_implementation", type: "address" },
            { internalType: "bytes", name: "_initData", type: "bytes" },
        ],
        name: "predictHATAirdropAddress",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract IHATAirdrop[]", name: "_airdrops", type: "address[]" },
            { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
            { internalType: "bytes32[][]", name: "_proofs", type: "bytes32[][]" },
            { internalType: "contract IHATVault[]", name: "_depositIntoVaults", type: "address[]" },
            { internalType: "uint256[]", name: "_amountsToDeposit", type: "uint256[]" },
            { internalType: "uint256[]", name: "_minShares", type: "uint256[]" },
            { internalType: "address", name: "_delegatee", type: "address" },
            { internalType: "uint256", name: "_nonce", type: "uint256" },
            { internalType: "uint256", name: "_expiry", type: "uint256" },
            { internalType: "uint8", name: "_v", type: "uint8" },
            { internalType: "bytes32", name: "_r", type: "bytes32" },
            { internalType: "bytes32", name: "_s", type: "bytes32" },
        ],
        name: "redeemAndDelegateMultipleAirdrops",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract IHATAirdrop[]", name: "_airdrops", type: "address[]" },
            { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
            { internalType: "bytes32[][]", name: "_proofs", type: "bytes32[][]" },
            { internalType: "contract IHATVault[]", name: "_depositIntoVaults", type: "address[]" },
            { internalType: "uint256[]", name: "_amountsToDeposit", type: "uint256[]" },
            { internalType: "uint256[]", name: "_minShares", type: "uint256[]" },
        ],
        name: "redeemMultipleAirdrops",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "contract IERC20", name: "_token", type: "address" },
            { internalType: "uint256", name: "_amount", type: "uint256" },
        ],
        name: "withdrawTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
