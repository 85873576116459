"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HATTokenLock_abi = void 0;
exports.HATTokenLock_abi = [
    { inputs: [], name: "AlreadyInitialized", type: "error" },
    { inputs: [], name: "AmountCannotBeZero", type: "error" },
    { inputs: [], name: "AmountRequestedBiggerThanSurplus", type: "error" },
    { inputs: [], name: "BeneficiaryCannotBeZero", type: "error" },
    { inputs: [], name: "CannotCancelAfterLockIsAccepted", type: "error" },
    { inputs: [], name: "CannotSweepVestedToken", type: "error" },
    { inputs: [], name: "CliffTimeMustBeBeforeEndTime", type: "error" },
    { inputs: [], name: "DelegateDisabled", type: "error" },
    { inputs: [], name: "LockIsAlreadyRevoked", type: "error" },
    { inputs: [], name: "LockIsNonRevocable", type: "error" },
    { inputs: [], name: "ManagedAmountCannotBeZero", type: "error" },
    { inputs: [], name: "NoAmountAvailableToRelease", type: "error" },
    { inputs: [], name: "NoAvailableUnvestedAmount", type: "error" },
    { inputs: [], name: "OnlyBeneficiary", type: "error" },
    { inputs: [], name: "OnlySweeper", type: "error" },
    { inputs: [], name: "PeriodsCannotBeBelowMinimum", type: "error" },
    { inputs: [], name: "ReleaseStartTimeMustBeBeforeEndTime", type: "error" },
    { inputs: [], name: "StartTimeCannotBeZero", type: "error" },
    { inputs: [], name: "StartTimeMustBeBeforeEndTime", type: "error" },
    { inputs: [], name: "TokenCannotBeZero", type: "error" },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: "address", name: "newBeneficiary", type: "address" }],
        name: "BeneficiaryChanged",
        type: "event",
    },
    { anonymous: false, inputs: [], name: "LockAccepted", type: "event" },
    { anonymous: false, inputs: [], name: "LockCanceled", type: "event" },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
            { indexed: true, internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "beneficiary", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "TokensReleased",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "beneficiary", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "TokensRevoked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "beneficiary", type: "address" },
            { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "TokensWithdrawn",
        type: "event",
    },
    { inputs: [], name: "acceptLock", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [],
        name: "amountPerPeriod",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "availableAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "beneficiary",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "canDelegate",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    { inputs: [], name: "cancelLock", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [{ internalType: "address", name: "_newBeneficiary", type: "address" }],
        name: "changeBeneficiary",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "currentBalance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "currentPeriod",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "currentTime",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "_delegatee", type: "address" }],
        name: "delegate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "duration",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "endTime",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_tokenLockOwner", type: "address" },
            { internalType: "address", name: "_beneficiary", type: "address" },
            { internalType: "contract HATToken", name: "_token", type: "address" },
            { internalType: "uint256", name: "_managedAmount", type: "uint256" },
            { internalType: "uint256", name: "_startTime", type: "uint256" },
            { internalType: "uint256", name: "_endTime", type: "uint256" },
            { internalType: "uint256", name: "_periods", type: "uint256" },
            { internalType: "uint256", name: "_releaseStartTime", type: "uint256" },
            { internalType: "uint256", name: "_vestingCliffTime", type: "uint256" },
            { internalType: "bool", name: "_revocable", type: "bool" },
            { internalType: "bool", name: "_canDelegate", type: "bool" },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "isAccepted",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "isInitialized",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "isRevoked",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "managedAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "passedPeriods",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "periodDuration",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "periods",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "releasableAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    { inputs: [], name: "release", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [],
        name: "releaseStartTime",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "releasedAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [],
        name: "revocable",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    { inputs: [], name: "revoke", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [],
        name: "sinceStartTime",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "startTime",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "surplusAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "contract IERC20", name: "_token", type: "address" }],
        name: "sweepToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "token",
        outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalOutstandingAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "vestedAmount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "vestingCliffTime",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        name: "withdrawSurplus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
