import {
  IEditedVulnerabilitySeverity,
  SeverityTemplate,
  createNewCoveredContract,
  createNewVulnerabilitySeverity,
  getVulnerabilitySeveritiesTemplate,
} from "@hats.finance/shared";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormInput, FormSelectInput, FormSelectInputOption, Pill } from "components";
import { useEnhancedFormContext } from "hooks/form/useEnhancedFormContext";
import { useOnChange } from "hooks/usePrevious";
import { useContext, useState } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IEditedVaultDescription } from "types";
import { VaultEditorFormContext } from "../../store";
import VulnerabilitySeverityForm from "./VulnerabilitySeverityForm/VulnerabilitySeverityForm";
import { StyledVulnerabilitySeveritiesList } from "./styles";

export function VulnerabilitySeveritiesList() {
  const { t } = useTranslation();
  const { control, register, setValue, trigger } = useEnhancedFormContext<IEditedVaultDescription>();
  const { fields, append, remove, replace } = useFieldArray({ control, name: "vulnerability-severities-spec.severities" });
  const watchFieldArray = useWatch({ control, name: `vulnerability-severities-spec.severities`, defaultValue: [] });
  const severities = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const [editSeverities, setEditSeverities] = useState(false);
  const vaultVersion = useWatch({ control, name: "version" });
  const isV1 = vaultVersion === "v1";
  const isAudit = useWatch({ control, name: "project-metadata.type" }) === "audit";
  const { allFormDisabled, isAdvancedMode, isEditingExistingVault } = useContext(VaultEditorFormContext);

  const severitiesTemplatesOptions: FormSelectInputOption[] = [
    {
      label: t("baseTemplate"),
      value: SeverityTemplate.base,
    },
    {
      label: t("gasTemplate"),
      value: SeverityTemplate.gas,
    },
    {
      label: t("fvTemplate"),
      value: SeverityTemplate.fv,
    },
    {
      label: t("gasFvTemplate"),
      value: SeverityTemplate.fvgas,
    },
  ];

  const templateName = useWatch({ control, name: "vulnerability-severities-spec.name" });
  const existentTemplate = !!Object.keys(SeverityTemplate).includes(templateName);

  // Change the vulnerability template and default on-chain params if the vault type changes
  useOnChange(templateName, (_, prevVal) => {
    if (prevVal === undefined) return;

    const vulnerabilitySeveritiesTemplate = getVulnerabilitySeveritiesTemplate(
      vaultVersion,
      isAudit,
      existentTemplate ? (templateName as SeverityTemplate) : SeverityTemplate.base
    );

    const severitiesIds = vulnerabilitySeveritiesTemplate.severities.map((s) => s.id as string);
    const severitiesOptionsForContractsCovered = vulnerabilitySeveritiesTemplate.severities.map(
      (s: IEditedVulnerabilitySeverity) => ({
        label: s.name,
        value: s.id as string,
      })
    );

    setValue("vulnerability-severities-spec.name", vulnerabilitySeveritiesTemplate.name);
    replace(vulnerabilitySeveritiesTemplate.severities);

    if (isAudit) setValue("contracts-covered", []);
    else setValue("contracts-covered", [{ ...createNewCoveredContract(severitiesIds) }]);

    setValue("severitiesOptions", severitiesOptionsForContractsCovered);
    trigger("vulnerability-severities-spec");
  });

  return (
    <StyledVulnerabilitySeveritiesList>
      {/* Select or vulnerabilites template */}
      {existentTemplate && isAudit && (
        <div className="mb-5 w-50">
          <Controller
            control={control}
            name={`vulnerability-severities-spec.name`}
            render={({ field, fieldState: { error } }) => (
              <FormSelectInput
                disabled={allFormDisabled || isEditingExistingVault}
                error={error}
                label={t("severitiesTemplate")}
                placeholder={t("severitiesTemplate")}
                colorable
                options={severitiesTemplatesOptions ?? []}
                noMargin
                {...field}
              />
            )}
          />
        </div>
      )}

      {isAdvancedMode && (
        <div className="mb-4">
          <FormInput
            {...register("usingPointingSystem")}
            noMargin
            disabled
            type="toggle"
            label={t("vaultEditorUseNewPointingSystem")}
          />
        </div>
      )}

      <div className="mb-4" dangerouslySetInnerHTML={{ __html: t("vaultEditorDefaultSeveritiesExplanation") }} />

      <div className="severities-pills mb-5">
        {severities.map((severity, index) => (
          <Pill key={index} isChecked text={severity.name.toLowerCase().replace("severity", "").trim()} />
        ))}
      </div>

      <FormInput
        name="editSeverities"
        checked={editSeverities}
        type="toggle"
        onChange={(e) => setEditSeverities(e.target.checked)}
        label={t("editSeverities")}
        colorable
      />

      {editSeverities && (
        <>
          <div className="helper-text" dangerouslySetInnerHTML={{ __html: t("vaultEditorSeveritiesExplanation") }} />

          {isV1 && (
            <div>
              <FormInput
                {...register(`vulnerability-severities-spec.indexArray`)}
                disabled={allFormDisabled}
                label={t("VaultEditor.severities-index-array")}
                colorable
                placeholder={t("VaultEditor.severities-index-array-placeholder")}
              />
            </div>
          )}

          {severities.map((severity, index) => (
            <VulnerabilitySeverityForm key={severity.id} index={index} remove={remove} severitiesCount={severities.length} />
          ))}

          {!allFormDisabled && (
            <Button styleType="invisible" onClick={() => append(createNewVulnerabilitySeverity(vaultVersion))}>
              <AddIcon className="mr-1" />
              <span>{t("addSeverity")}</span>
            </Button>
          )}
        </>
      )}
    </StyledVulnerabilitySeveritiesList>
  );
}
